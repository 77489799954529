import { Flex, Heading, Container, Text } from "@radix-ui/themes";

const Gifts = () => {
  return (
      <Container>
          <Flex direction={"column"} align="center">

            <Heading mt="9" size={"9"}>Gifts</Heading>

            <Text
              mt="3"
              size="7"
              weight="light"
              className="main-heading"
              align="center"
            >
            Hello!

            </Text>
            <Text
              mt="1"
              size="7"
              weight="light"
              className="main-heading"
              align="center"
            >

  We’re thrilled to be starting our married life with a fantastic honeymoon. Instead of traditional gifts, we’d greatly appreciate contributions to our honeymoon fund. Your generosity will help us create unforgettable memories.

            </Text>
            <Text
              mt="1"
              size="7"
              weight="light"
              className="main-heading"
              align="center"
            >

Thanks for being brilliant and helping us make this adventure special!

            </Text>
            
            
            <Text
              mt="6"
              size="7"
              weight="bold"
              className="main-heading"
              align="center"
            >
              You can either Bank Transfer to the details below, or give us some cash/a cheque :)
            </Text>
            <Text
              mt="1"
              size="6"
              weight="light"
              className="main-heading"
              align="center"
            >
              Name: MR LUKE A J WORGAN <br />
              Sort Code: 77-48-17 <br />
              Account Number: 63228568 <br />
            </Text>
          </Flex>
      </Container>
  );
};

export default Gifts;
