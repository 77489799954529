import { Box, Flex, Theme, Text } from "@radix-ui/themes";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Gifts from "./pages/gifts";
import Home from "./pages/home";

export const routesConfig = {
  HOME: {
    path: "/",
    component: Home,
  },
  GIFTS: {
    path: "/gifts",
    component: Gifts,
  },
};

const App = () => {
  const navigate = useNavigate();
  return (
    <Routes>
      {Object.values(routesConfig).map(
        ({ path, component: Component }, index) => (
          <Route
            key={index}
            path={path}
            element={
              <>
                <Theme
                  appearance={"light"}
                  accentColor={"orange"}
                  radius={"medium"}
                  panelBackground={"translucent"}
                  hasBackground={true}
                  scaling={"100%"}
                >
                  <Box className={`main-body`}>
                    <Flex justify={{ initial: "center" }} mt="9">
                      <Flex align={"center"}>
                        <a href="#"                          onClick={() => {navigate("/")}}>
                          <Text className="selected-link">Wedding</Text>
                        </a>
                        <a
                          target={"_blank noreffer"}
                          href="https://maps.app.goo.gl/8K74mTZ6MPeLpyx79"
                        >
                          <Text ml="3">Location</Text>
                        </a>

                        <a
                         onClick={() => {navigate("/gifts")}}
                        >
                          <Text ml="3">Gifts</Text>
                        </a>

                        <a
                          target={"_blank noreffer"}
                          href="https://amberandluke.s3.eu-west-1.amazonaws.com/Local+Accommodation.pdf"
                        >
                          <Text ml="3">Accomodation</Text>
                        </a>

                        <a
                          target={"_blank noreffer"}
                          href="https://amberandluke.s3.eu-west-1.amazonaws.com/Taxis+List+2022.pdf"
                        >
                          <Text ml="3">Taxis</Text>
                        </a>
                      </Flex>
                    </Flex>
                    <Component />
                  </Box>
                </Theme>
              </>
            }
          />
        )
      )}
      <Route path="*" element={<Navigate to={routesConfig["HOME"].path} />} />
    </Routes>
  );
};

export default App;
