import { Flex, Heading, Container, Text } from "@radix-ui/themes";

const Home = () => {
  return (
    <Container>
      <Flex
        direction={"column"}
        justify="center"
        align={"center"}
        grow="1"
        mt="9"
      >
        <Flex direction={"column"} align={"center"}>
          <Flex direction={{ initial: "column", md: "row" }} align={"center"}>
            <Text size={"6"} align="center">
              Saturday 3rd August 2024
            </Text>
            <Text size={"6"} mx={"3"} align="center">
              •
            </Text>
            <Text size={"6"} align="center">
              Iscoyd Park
            </Text>
          </Flex>
        </Flex>

        <Heading
          as="h1"
          size={"9"}
          weight="light"
          className="main-heading"
          my="6"
          align="center"
        >
          Amber and Luke's Wedding
        </Heading>
      </Flex>
      <Flex direction={"column"} justify="center" align={"center"} mt="6">
        <Flex
          direction={{ initial: "column" }}
          align={"center"}
          justify="between"
        >
          <Text size="6" weight="light" className="main-heading" align="center">
            Guests should arrive between 13:00 and 13:30 - Ceremony starts at
            14:00
          </Text>
        </Flex>
      </Flex>
      <Flex direction={"column"} justify="center" align={"center"} mt="9">
        <Text size="6" weight="light" className="main-heading" align="center">
          If you are not staying at Iscoyd Park you will have to pre-book
          carriages for midnight.
        </Text>
      </Flex>
      <Flex direction={"column"} justify="center" align={"center"} mt="9">
        <Heading size={"9"}>FAQs and Clarifications:</Heading>
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          What time should I arrive?{" "}
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          If you are staying at the venue, you can check in from 12:00.
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          If you are not staying at the venue, please arrive between 13:00 and
          13:30. (Ceremony starts at 14:00)
        </Text>
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          Am I an Evening Guest or a Day Guest?
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          Day Guest! There are NO evening guests
        </Text>
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          Are there any +1s?
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          No +1s i'm afraid. Only those who received an invitation.
        </Text>
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          When will I eat?
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          Canapés and reception drinks will be served from 2:45pm
          <br />
          Wedding breakfast and speeches will begin at 4:30pm
          <br />
          Pizza will be served in the evening from 9pm <br />{" "}
        </Text>
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          Is there parking available at the venue?
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          Yes, there is parking available at the venue. Please follow the
          directions provided.
        </Text>
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          If i am staying at the venue, when do i have my room until?{" "}
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          Breakfast is served from 9am in the coach house. <br />
          Checkout from rooms is 10am latest. Checkout from the coach house
          (where breakfast is served) is 11am.
        </Text>
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          What is the dress code?{" "}
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          Normal summer wedding dress code - get your self in your glad rags!
        </Text>
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          Where do I stay?
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          If you haven't already booked somewhere, there is an Accommodation
          list at the top of this page.
        </Text>
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          When do I need to leave by?
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          If you are not staying at the venue you need to leave by Midnight. It
          is important you book your taxi in advance (definitely not on the
          night of). There is a taxi list linked at the top of this page.
        </Text>
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          Do I need to dance?
        </Text>
        <Text
          mt="1"
          size="6"
          weight="light"
          className="main-heading"
          align="center"
        >
          Yes of course you bloody do.
        </Text>{" "}
        <Text
          mt="7"
          size="7"
          weight="bold"
          className="main-heading"
          align="center"
        >
          If you have any questions or something is ambiguous, please can you
          contact us and ask the question so we can add the answer to this FAQ,
          please and thankyou :)
        </Text>
      </Flex>
    </Container>
  );
};

export default Home;
